
import { Component, Mixins, Vue } from 'vue-property-decorator'
import LoginMethods from '@/components/organisms/LoginMethods.vue'
import BaseQuiz from '@/mixins/quiz/BaseQuiz'

@Component
export default class AuthenQuiz extends Mixins(LoginMethods, BaseQuiz) {
  private async created() {
    const bookCode = (this.$route.query?.book || '') as string
    bookCode && localStorage.setItem('bookCode', bookCode)
    this.mode = this.MODE.QUIZ
    Vue.prototype.$loading.startBgWhite()
    const liff = await this.initLiff()

    if (!liff.isLoggedIn()) {
      return this.goErrorPage()
    }
    const token = liff.getAccessToken() as string
    if (!token) {
      return this.goErrorPage()
    }
    this.loginWithLine(token, this.nextPage).finally(() => Vue.prototype.$loading.complete())
    this.saveProfileLine()
    Vue.prototype.$loading.complete()
  }
  private async nextPage() {
    const bookCode = localStorage.getItem('bookCode')
    if (!bookCode) {
      return this.goErrorPage('書籍を選択してください。')
    }
    await Vue.prototype.$http.httpWithToken
      .patch('/v3/history_quiz/user_quiz_original_curriculums', { original_curriculum_code: bookCode })
      .then(async ({ data }: any) => {
        const objectUnit = {
          CurriculumLUnit: 'l',
          CurriculumMUnit: 'm',
          CurriculumSUnit: 's',
        }
        this.$store.commit('badges/setListBadge', data.badges)
        this.saveBookInfo({
          id: data.id,
          name: data.name,
          code: bookCode,
          level: objectUnit[data.top_unit],
          first_parent_id: data.top_parent_id,
        })
        this.$router.replace(`/quiz/units/${objectUnit[data.top_unit]}/${data.top_parent_id}`)
        localStorage.removeItem('bookCode')
        return
      })
  }
  private goErrorPage(mgs?: string) {
    this.$router.push(`quiz/error${mgs ? '?errorMgs=' + mgs : ''}`)
  }
}
